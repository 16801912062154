import * as  React from "react";


const map = ({ data, mobile }) => {
  return (
    <div className="text-left m-auto  main-container-map">
      <div className="d-grid two-columns">
        <div>
          <p className="white IBlack fs-3 line-height-1">
            <a href={data.address.contact.href} target="_blank" rel="noreferrer" aria-label={data.address.contact.display}>{data.address.contact.display}</a></p>
          <p className={mobile ? "white IBlack fs-6 line-height-1 mb-3 text-center": "white IBlack fs-6 line-height-1 mb-3"}>{data.address.contact.displaysmall}</p>
          {data.address.blue.map((address, i) =>(
            <p className="blue fs-5 ISemiBold  text-uppercase" key={'address'+i}>{address}</p>
          ))}
           <p className="grey fs-5 ISemiBold text-uppercase line-height-2">{data.address.grey}</p>
        </div>
        <div>
          <iframe title="map" src={data.map} width="100%" height="600px" style={{border:"none"}}></iframe>
        </div>
      </div>
    </div >
  );
}
export default map;